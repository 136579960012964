import React, { useState, useEffect, useMemo } from 'react'
import _ from 'lodash'
import styled, { css } from 'styled-components'
import animateScrollTo from 'animated-scroll-to'
import { darken } from 'polished'
import colors from 'app/lib/colors'
import t from 'app/lib/i18n'

import CongratulationsCard from './components/CongratulationsCard'
import ListSelection from './components/ListSelection'

import NextButton from './NextButton'
import { getDiet, getDietType, getDietTypes } from './logic'
import { AUTH_RESET_PASSWORD_DONE, AUTH_RESET_PASSWORD_FAILED } from "../../../actions/auth";
import { apiCall } from "../../../lib/api";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`

const Interstitial = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  background: #1a6161;
  color: #fff;
`

const InterstitialInner = styled.div`
  position: absolute;
  top: 50%;
  right: 20%;
  left: 20%;
  transform: translate3d(0, -50%, 0);
`

const Button = styled.div`
  width: 200px;
  margin: 15px auto;
  padding: 10px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: 1px solid ${colors.gray};
  color: ${colors.gray};
  border-radius: 100px;
  font-weight: bold;
  font-size: 13px;
  cursor: pointer;
  text-align: center;

  ${props =>
    props.primary &&
    css`
      border-color: ${props => props.theme.primary};
      color: ${props => props.theme.primary};
    `}

  .small {
    margin-top: 3px;
    font-size: 11px;
  }
`

const GoalsList = styled.div`
  text-align: center;

  .goals-hd {
    text-transform: uppercase;
    color: ${props => props.theme.black};
    font-weight: bold;
  }

  .goal {
    position: relative;
    display: inline-block;
    padding: 10px 15px;
    max-width: 100px;
    vertical-align: middle;
    color: ${colors.text};

    :before {
      position: absolute;
      right: 0;
      top: calc(50% - 12px);
      display: block;
      content: '';
      height: 24px;
      width: 1px;
      background: ${props => props.theme.primary};
    }

    :last-child:before {
      display: none;
    }
  }
`

const DietListInstructions = styled.div`
  margin: 10px 0;
  max-width: 250px;
  font-size: 20px;
  color: ${colors.text};
  text-align: center;
`

const DietImage = styled.div`
  position: relative;
  margin-top: 12px;
  width: 300px;
  height: ${(300 / 1125) * 1400}px;
  border: 1px solid #ccc;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`

const DietWrap = styled.div`
  margin: 0 -12px 0 -32px;

  .label {
    margin-left: 26px;
    font-weight: bold;
    color: ${props => props.theme.black};
  }

  &.featured {
    ${DietImage} {
      float: left;
      margin-right: 10px;
      width: 250px;
      height: ${(250 / 1125) * 1400}px;
    }
  }
`

const DietDescription = styled.div`
  margin-top: 10px;
  font-size: 14px;
  color: ${colors.text};
`

const DietWarning = styled.div`
  margin-top: 10px;
  font-size: 14px;
  color: ${colors.text};

  span {
    padding: 0 5px 5px 0;
  }
`

const WarningTag = styled.div`
  display: inline-block;
  padding: 0 10px;
  margin: 0 5px 5px 0;
  line-height: 20px;
  border-radius: 10px;
  background: ${colors.error};
  color: ${darken(0.5, colors.error)};
`

// const idealFields = {
//   for_health: {
//     label: window.location.href.includes('tangelo') || window.location.href.includes('tangelorx') ? 'What Health Conditions do you have?' : 'What are your health goals?',
//     name: 'for_health',
//     single_only: false,
//     options: [
//       /*{ value: 'reduce_cardio_risk', label: 'Improve heart health' },
//       { value: 'control_bp', label: 'Control blood pressure' },
//       { value: 'prevent_control_diabetes', label: 'Prevent and control diabetes' },
//       { value: 'manage_heart_failure', label: 'Manage heart failure' },
//       { value: 'decrease_inflammation', label: 'Immunity support' },
//       { value: 'improve_overall', label: 'Improve overall health' },
//       {
//         value: 'manage_weight',
//         label: 'Manage weight',
//         childLists: [
//           {
//             label: 'Do you want to:',
//             name: 'weight_goal',
//             single_only: true,
//             mandatory: true,
//             options: [
//               { value: 'lose', label: 'Lose weight' },
//               { value: 'maintain', label: 'Maintain weight' },
//               { value: 'gain', label: 'Gain weight' }
//             ]
//           }
//         ]
//       },
//       { value: 'manage_high_cholesterol', label: 'Manage High Cholesterol' },
//       { value: 'manage_food_sensitivities', label: 'Manage Food Sensitivities' },
//       { value: 'improve_gut_health', label: 'Improve Gut Health' },
//       { value: 'other', label: 'Other' }*/
//     ]
//   }
//   /*for_diet: {
//     label: "For My Diet",
//     name: "for_diet",
//     single_only: false,
//     options: [
//       {value: "portion_control", label: "Portion control"},
//       {value: "lower_carbs", label: "Lower carb consumption"},
//       {value: "eat_more_clean", label: "Eat more 'clean'"},
//       {value: "eat_healthy_consistently", label: "Eat healthy consistently"},
//       {value: "eat_more_plants", label: "Eat more plant-based"},
//       {value: "eat_for_planet", label: "Eat for a better planet"},
//     ],
//   },
//   goal_diet: {
//     label: "I have a goal diet",
//     name: "goal_diet",
//     single_only: true,
//     options: [
//       {value: "vegan", label: "Go Vegan"},
//       {value: "vegetarian", label: "Go Vegetarian"},
//       {value: "paleo", label: "Go Paleo"},
//       {value: "mediterranean", label: "Go Mediterranean"},
//     ],
//   },*/
// }

const initializeIdealFields = (partner) => ({
  for_health: {
    label: partner?.is_tangelo ? 'What Health Conditions do you have?' : 'What are your health goals?',
    name: 'for_health',
    single_only: false,
    options: []
  }
})

const goals = [
  {
    goal: 'manage_high_cholesterol',
    rank: 1,
    diets: ['TLC', 'POF', 'ORN', 'AHA', 'DSH', 'MED', 'FLX', 'VET', 'VEG', 'WFP', 'WFR', 'MND', 'PES']
  },
  { goal: 'manage_food_sensitivities', rank: 2, diets: ['AME_GF', 'VET_GF', 'VEG_GF', 'LFM', 'VEG', 'PAL'] },
  { goal: 'improve_gut_health', rank: 3, diets: ['VET_GF', 'AME_GF', 'VEG_GF', 'LFM', 'VEG', 'PAL'] },
  {
    goal: 'reduce_cardio_risk',
    rank: 4,
    diets: ['AHA', 'TLC', 'ORN', 'DSH', 'POF', 'MND', 'MED', 'PES', 'FLX', 'VET', 'VEG', 'WFP', 'WFR']
  },
  {
    goal: 'control_bp',
    rank: 5,
    diets: ['DSH', 'AHA', 'TLC', 'FLX', 'VET', 'VEG', 'WFP', 'MED', 'MND', 'PES', 'WFR', 'ORN']
  },
  {
    goal: 'prevent_control_diabetes',
    rank: 6,
    diets: ['DSH', 'LOC', 'MED', 'AHA', 'TLC', 'MND', 'PES', 'FLX', 'VET', 'VEG', 'WFP', 'WFR']
  },
  {
    goal: 'manage_heart_failure',
    rank: 7,
    diets: ['DSH', 'AHA', 'FLX', 'VET', 'VEG', 'WFP', 'SA_V']
  },
  { goal: 'decrease_inflammation', rank: 8, diets: ['MED', 'MND', 'PES', 'FLX', 'VET', 'VEG', 'WFP', 'WFR'] },
  {
    goal: 'improve_overall',
    rank: 9,
    diets: ['current', 'AHA', 'DSH', 'MND', 'MED', 'FLX', 'PES', 'VET', 'VEG', 'WFP', 'TLC', 'WFR', 'SA', 'SA_V']
  },
  {
    goal: 'manage_weight',
    qualifier: 'lose',
    rank: 10,
    diets: ['FLX', 'MED', 'DSH', 'VET', 'VEG', 'WFP', 'WFR', 'LOF', 'LOC']
  },
  {
    goal: 'manage_weight',
    qualifier: 'maintain',
    rank: 10,
    diets: ['FLX', 'MED', 'DSH', 'VET', 'VEG', 'WFP', 'WFR', 'LOF', 'LOC']
  },
  { goal: 'manage_weight', qualifier: 'gain', rank: 10, diets: ['current', 'PAL', 'LOC', 'MED'] },
  {
    goal: 'other',
    rank: 11,
    diets: ['current', 'AHA', 'DSH', 'MND', 'MED', 'FLX', 'PES', 'VET', 'VEG', 'WFP', 'TLC', 'WFR', 'SA', 'SA_V']
  }
]

const getDietsForHealthGoalsFromBackend = (healthGoals, setGoalDietsBackend, workflow_id) => {
  if (healthGoals && healthGoals.length && workflow_id && setGoalDietsBackend) {
    try {
      apiCall('workflows/' + workflow_id + '/health_goal_diets', {
        method: 'POST',
        data: {
          health_goal_slugs: healthGoals
        }
      }).then(resp => {
        setGoalDietsBackend(resp.data);
      })
    } catch (e) {
      //
    }
  }
}

const getDietsForHealthGoals = (matrix, idDiet, healthGoals, weightGoals, partner) => {
  // custom values are stored like "other:I have this other goal", so remove the custom portion
  healthGoals = healthGoals.map(goal => goal.split(':')[0])

  const dietTypes = getDietTypes()
  //const idDietType = idDiet ? getDietType(matrix, idDiet) : null
  const idDietType = getDietType(matrix, idDiet)

  const tokens = {
    current: idDietType,
    east: matrix.columns.slice(matrix.columns.indexOf(idDietType) + 1)
  }
  const goalFilter = goal =>
    healthGoals.includes(goal.goal) && (goal.goal !== 'manage_weight' || goal.qualifier === weightGoals[0])
  const goalSorter = (g1, g2) => g1.rank - g2.rank
  const goalMapper = goal => _.uniq(_.flatten(goal.diets.map(diet => tokens[diet] || diet)))
  
//  const selectedGoals = goals.filter(goalFilter).sort(goalSorter).map(goalMapper).filter(item => !partner.hidden_ideals.includes(item)) - attempt
  const selectedGoals = goals.filter(goalFilter).sort(goalSorter)
  const bestMustBeCurrent = ((selectedGoals[0] || {}).diets || [])[0] === 'current'
  const managingHeartFailure = selectedGoals.some(goal => goal.goal === 'manage_heart_failure')
  const managingHighCholesterol = selectedGoals.some(goal => goal.goal === 'manage_high_cholesterol')
  const managingFoodSensitivities = selectedGoals.some(goal => goal.goal === 'manage_food_sensitivities')
  const improvingGutHealth = selectedGoals.some(goal => goal.goal === 'improve_gut_health')
  const improvingOverall = selectedGoals.some(goal => goal.goal === 'improve_overall')
  let idIsIdeal = false


  //  selectedGoals.map(goalMapper).filter(item => alert('bb '+JSON.stringify(item)))
  let diets = selectedGoals.map(goalMapper).filter(item => !partner.hidden_ideals.includes(item))

  // always include the #1 diet for the highest ranking goal (grab early because it may not be present after intersecting the arrays)
  const best = _.compact([(diets[0] || [])[0]])

  diets = _.intersection(...diets)

  if (getDiet(matrix, idDiet).diet.quality === 10 && diets.includes(idDietType) && !bestMustBeCurrent) {
    //  if ((getDiet(matrix, idDiet).diet.quality === 10 || (getDiet(matrix, idDiet).diet.name === 'Keto' && getDiet(matrix, idDiet).diet.quality > 6)) && diets.includes(idDietType) && !bestMustBeCurrent) {
    const idDietRank = diets.indexOf(idDietType)
    // alert('success 2 - ', idDietRank, idDietType, best[0])
    // if the user is already eating the best quality of the best diet for reaching their health goals, stick with it
    if (idDietType === best[0]) {
      idIsIdeal = true
      // alert('success 3 - idIsIdeal')
    }

    // don't include ideal diets that are inferior to the user's current diet in reaching their health goals
    diets = diets.slice(0, idDietRank + 1)
  }
  const ethnicDiets = ['CAR_WI', 'SA', 'SA_V', 'MEX', 'MEX_V', 'VTM', 'KOR', 'CEN', 'CES', 'SOU']
  const poor =
    managingHeartFailure && idDietType === 'MEX' ? ['AME', 'NRM', 'LOF', 'SOU'] : ['AME', 'NRM', 'LOF', 'SOU', 'MEX']
  const better = _.difference(diets, best, poor)
  const all = _.compact(
    _.uniq(
      dietTypes.sort((d1, d2) => d1.name.toLowerCase().localeCompare(d2.name.toLowerCase())).map(diet => diet.code)
    )
  )
  //const good = managingHeartFailure || managingHighCholesterol || managingFoodSensitivities || improvingGutHealth      ? []      : _.difference(all, best, better, poor)
  const good = _.difference(all, best, better)

  // - SA/V filters
  let filteredGood = good; // .filter(item => partner.hidden_ideals.includes(item)); // idDietType !== 'SA' ? good.filter(item => item !== 'SA') : good
  //filteredGood = filteredGood; // idDietType !== 'SA_V' ? filteredGood.filter(item => item !== 'SA_V') :
  

  //CEN / CES
 // filteredGood = idDietType !== 'CEN' ? filteredGood.filter(item => item !== 'CEN') : filteredGood
 // filteredGood = idDietType !== 'CES' ? filteredGood.filter(item => item !== 'CES') : filteredGood
  
  let filteredBetter = idDietType !== 'SA' ? better.filter(item => item !== 'SA') : better
  filteredBetter = idDietType !== 'SA_V' ? filteredBetter.filter(item => item !== 'SA_V') : filteredBetter
  filteredBetter = idDietType !== 'MEX' ? filteredBetter.filter(item => item !== 'MEX') : filteredBetter
  filteredBetter = idDietType !== 'MEX_V' ? filteredBetter.filter(item => item !== 'MEX_V') : filteredBetter
  filteredBetter = idDietType !== 'VTM' ? filteredBetter.filter(item => item !== 'VTM') : filteredBetter
  
  let filteredBest = idDietType !== 'SA' ? best.filter(item => item !== 'SA') : best
  filteredBest = idDietType !== 'SA_V' ? filteredBest.filter(item => item !== 'SA_V') : filteredBest
  filteredBest = idDietType !== 'MEX' ? filteredBest.filter(item => item !== 'MEX') : filteredBest
  filteredBest = idDietType !== 'MEX_V' ? filteredBest.filter(item => item !== 'MEX_V') : filteredBest
  filteredBest = idDietType !== 'VTM' ? filteredBest.filter(item => item !== 'VTM') : filteredBest


  if (selectedGoals.length > 1) {
    // add top diets from each goal
    for (let i = 0; i < selectedGoals.length; i++) {
      if (!filteredBest.includes(selectedGoals[i].diets[0]) && !filteredBetter.includes(selectedGoals[i].diets[0])) {
        filteredBetter.push(selectedGoals[i].diets[0] === 'current' ? idDietType : selectedGoals[i].diets[0])
      }
      // if either IGH or MFS
      if (
        !filteredBest.includes('VEG_GF') &&
        (selectedGoals[i].goal === 'manage_food_sensitivities' || selectedGoals[i].goal === 'improve_gut_health')
      ) {
        filteredBetter.push('VEG_GF')
      }

      if (filteredGood.length + filteredBetter.length + filteredBest.length === 0) {
        //alert('found')
        filteredBetter.push('VEG_GF', 'VET_GF')
      }
    }
  }

  const getOptionFromCode = code => {
    const diet = dietTypes.find(diet => diet.code === code)
    try {
      if (code !== 'current') {
        return {
          value: code,
          current: code === idDietType,
          label:
            code === idDietType ? (
              <>
                <span className="id-diet-tag">{t('Your current diet type')}</span>
                {diet !== undefined && diet.name !== undefined ? diet.name : ''}
              </>
            ) : (
              diet.name
            ),
          description: diet?.description
        }
      }
    } catch (e) {
      //console.log(e, code)
    }
  }
  return {
    best: filteredBest,
    idIsIdeal,
    fields: [
      ...(filteredBest.length
        ? [
            {
              label: 'Top recommendation based on goals',
              name: 'goal_diet',
              single_only: true,
              mandatory: true,
              options: filteredBest.length ? filteredBest.map(getOptionFromCode) : []
            }
          ]
        : []),
      ...(filteredBetter.length
        ? [
            {
              label: 'Other good diets for your goals',
              name: 'goal_diet',
              single_only: true,
              mandatory: true,
              options: filteredBetter.length ? filteredBetter.map(getOptionFromCode) : [] // .slice(0, 4) - google wants a max
            }
          ]
        : []),
      ...((ethnicDiets.includes(idDietType) && !filteredBest.includes(idDietType) && !filteredBetter.includes(idDietType)) // && (!filteredBest.length || !filteredBetter.length) ||  && !diets.length
        ? [
          {
            label: 'Other diet types',
            name: 'goal_diet',
            single_only: true,
            mandatory: true,
            options: [ethnicDiets.includes(idDietType) ? idDietType : filteredGood].map(getOptionFromCode)
          }
        ]
        : [])
    ]
  }
}
/*,
      ...(filteredGood.length
        ? [
            {
              label: 'Other diet types',
              name: 'goal_diet',
              single_only: true,
              mandatory: true,
              options: filteredGood.length ? filteredGood.map(getOptionFromCode) : []
            }
          ]
        : [])*/

const getDietsWithoutHealthGoals = (matrix, idDiet) => {
  const dietTypes = getDietTypes()
  const idDietType = getDietType(matrix, idDiet)
  /*
  console.log('getDietsWithoutHealthGoals dietTypes ', dietTypes)
  console.log('getDietsWithoutHealthGoals idDiet ', idDiet)
  console.log('getDietsWithoutHealthGoals matrix ', matrix)
  console.log('getDietsWithoutHealthGoals idDietType ', idDietType)*/
  const getOptionFromCode = code => {
    const diet = dietTypes.find(diet => diet.code === code)
    //console.log('getOptionFromCode code, diet ', code, diet)
    return {
      value: code,
      label: code === idDietType ? `${t('Keep my diet type as')} ${diet?.name}` : diet?.name,
      current: code === idDietType,
      description: diet?.description
    }
  }

  const fields = [
    { label: '', diets: [idDietType] },
    {
      label: 'Plant forward',
      diets: ['FLX', 'VET', 'VEG', 'WFP', 'WFR', 'PES', 'MED'].filter(code => code !== idDietType)
    },
    {
      label: 'Therapeutic',
      diets: ['POF', 'ORN', 'LFM', 'AME_GF', 'VET_GF', 'VEG_GF', 'DSH', 'MND', 'TLC', 'AHA'].filter(
        code => code !== idDietType
      )
    },
    { label: 'Other', diets: ['PAL', 'LOC'].filter(code => code !== idDietType) }
  ]
    .filter(item => !item.diets.includes('KET'))
    .map(({ label, diets }) => ({
      label,
      name: 'goal_diet',
      single_only: true,
      mandatory: true,
      options: diets.map(getOptionFromCode)
    }))

  return { fields }
}

const updateProgress = (path, photoSelectionProgress, onProgressChange) => {
  const paths = [
    ['Interstitial', 'HealthGoalDialog', 'DietList'],
    ['Interstitial', 'HealthGoalDialog', 'HealthGoalSelection', 'DietList'],
    ['Interstitial', 'HealthGoalDialog', 'HealthGoalSelection', 'HealthGoalDietList']
  ].filter(possiblePath => possiblePath.every((step, i) => !path[i] || path[i] === step))

  const pathProgress = Math.max(0, Math.min(...paths.map(possiblePath => (path.length - 2) / possiblePath.length)))

  const maxSteps = Math.max(...paths.map(possiblePath => possiblePath.length))
  const completedSteps = Math.min(
    ...paths.filter(p => p.length === maxSteps).map(possiblePath => possiblePath.indexOf(path[path.length - 1]))
  )

  // onProgressChange('IdealInfo', pathProgress + (1 - pathProgress) * photoSelectionProgress)
  // decrement steps as long as interstitial step is skipped
  onProgressChange('IdealInfo', completedSteps - 1, maxSteps - 2)
}

const getIdDiet = (matrix, responses) =>
  (responses.diet_id || []).length > 1 && responses.diet_id[responses.diet_id.length - 1] === true
    ? responses.diet_id[responses.diet_id.length - 2]
    : null

/*
for_health: ["decrease_inflammation", "improve_overall"]
goal_diet: ["PES"]
// ? ideal_goals: { weight_goal: ["maintain"] }
*/

export default function Ideal(props) {
  const {
    step: { responses, matrix, presets, available_health_goals, workflow_id },
    onProgressChange,
    onSubmit,
    scrollRef,
    partner
  } = props

  // const idealFields = {
  //   for_health: {
  //     label: partner.is_tangelo ? 'What Health Conditions do you have?' : 'What are your health goals?',
  //     name: 'for_health',
  //     single_only: false,
  //     options: []}
  // }

  const [path, setPath] = useState(['Interstitial', 'HealthGoalDialog']) //, 'HealthGoalSelection']) //, 'HealthGoalDietList']) //, 'PhotoSelection'])
  const [values, setValues] = useState({})
  const [didPresets, setDidPresets] = useState(false)
  const [animatingOut, setAnimatingOut] = useState(false)
  const [goalDietsBackend, setGoalDietsBackend] = useState([])


  const [idealFields, setIdealFields] = useState(initializeIdealFields(partner))

  useEffect(() => {
    const healthGoals = available_health_goals.filter(v => !partner?.hidden_goals.includes(v.value));
    idealFields.for_health.options = healthGoals;
  }, [values])
  
  //let { translations } = partner ?? []
  //translations = translations.filter(item => item.locale === 'en')
  //const idealTitle = translations && translations.filter(item => item.slug == 'Here is your ID')[0] && translations.filter(item => item.slug == 'Here is your ID')[0].term ? t(translations.filter(item => item.slug == 'Here is your ID')[0].term) : message
  
  useEffect(() => {
    if (!didPresets && presets && values['for_health']) {
      //console.log({ ...values, ['for_health']: presets }) && alert(JSON.stringify({ ...values, ['for_health']: presets }))
      setValues(values => ({ ...values, ['for_health']: presets }))
      setDidPresets(true)
    }}, [presets, values, didPresets])


  // const [photoSelections, setPhotoSelections] = useState([]) // step.responses[step.step] || []
  // const [photoSelectionProgress, setPhotoSelectionProgress] = useState(0)
  const photoSelectionProgress = 0
  const idDiet = getIdDiet(matrix, responses)

  const goToStep = (step, animateOutDuration = 0) => {
    const doGoToStep = () => setPath([...path, step])

    if (animateOutDuration) {
      setAnimatingOut(true)

      setTimeout(() => {
        doGoToStep()
        setAnimatingOut(false)
      }, animateOutDuration)

      return
    }

    doGoToStep()
  }

  const step = path[path.length - 1]

  useEffect(() => {
    const element = scrollRef()

    if (element) {
      animateScrollTo(0, { element, speed: 1000 })
    }
  }, [step])

  useEffect(() => {
    // TODO: temporarily allowing field.key instead of field.name for backwards-compatibility
    setValues(
      props.step.fields.reduce(
        (values, field) => ({
          ...values,
          [field.name || field.key]: field.options.filter(option => option.selected).map(option => option.value)
        }),
        {}
      )
    )
  }, [])

  useEffect(() => updateProgress(path, photoSelectionProgress, onProgressChange), [
    path,
    photoSelectionProgress,
    onProgressChange
  ])
  {
    /*
  const goalDiets = useMemo(
    () => getDietsForHealthGoals(matrix, idDiet, values.for_health || [], values.weight_goal || [], props.partner),
    [matrix, idDiet, values.for_health, values.weight_goal, props.partner]
    */
  }
  useEffect(
    () =>
      getDietsForHealthGoalsFromBackend(
        values.for_health || [],
        setGoalDietsBackend,
        workflow_id,
      ),
    [values.for_health, workflow_id, setGoalDietsBackend]
  )
  const goalDiets = useMemo(() => goalDietsBackend,[goalDietsBackend])
  
  const regularDiets = useMemo(() => getDietsWithoutHealthGoals(matrix, idDiet), [matrix, idDiet])

  const optionAnimationDelay = 50
  let listAnimationDelay = 0

  const getListSelectionProps = (field, initialDelay = 0) => {
    let { options, ...fieldProps } = field

    listAnimationDelay = listAnimationDelay + initialDelay

    if (options) {
      options = options.map(option => {
        const { childLists, ...optionProps } = option

        const outerListAnimationDelay = listAnimationDelay
        listAnimationDelay = 0

        const optionList = {
          ...optionProps,
          ...(childLists ? { childLists: childLists.map(field => getListSelectionProps(field)) } : {})
        }

        listAnimationDelay = outerListAnimationDelay

        return optionList
      })
    }

    const animationDelay = listAnimationDelay

    if ((options || []).length) {
      listAnimationDelay = listAnimationDelay + 300 + (options.length - 1) * optionAnimationDelay
    }

    return {
      ...fieldProps,
      ...(options ? { options } : {}),
      selections: values[field.name] || [], // presets
      onChange: selections => setValues(values => ({ ...values, [field.name]: selections })),
      animate: true,
      animationDelay
    }
  }

  if (step === 'Interstitial') {
    setTimeout(() => goToStep('HealthGoalDialog'), 2000)

    return (
      <Interstitial>
        <InterstitialInner>{t("Now let's find your ideal diet")}</InterstitialInner>
      </Interstitial>
    )
  }

  if (step === 'HealthGoalDialog') {
    if (partner.suppress_healthgoals) {
      goToStep('HealthGoalSelection')
    }
    return (
      <Wrap>
        <ListSelection label="Do you have specific health goals?">
          <Button onClick={() => goToStep('HealthGoalSelection')} primary>
            {t('Yes').toUpperCase()}
            <div className="small">{t('Show me the choices')}</div>
          </Button>
          <Button onClick={() => goToStep('DietList')}>
            {t('No').toUpperCase()}
            <div className="small">{t('Skip to the next step')}</div>
          </Button>
        </ListSelection>
      </Wrap>
    )
  }

  if (step === 'DietList') {
    let filteredRegularDiets = []
    regularDiets.fields.forEach((field, index) => {
      let new_field = field
      new_field.options = new_field.options.filter(
        item => !props.partner.hidden_ideals.includes(item.value) || item.current
      )
      filteredRegularDiets.push(new_field)
    })
    return (
      <Wrap>
        {filteredRegularDiets.length > 0 &&
          filteredRegularDiets.map(field => {
            //alert(JSON.stringify(field))
            return (
              field.options.length > 0 && (
                <ListSelection key={field.name + field.label} {...getListSelectionProps(field)} single_only={true} />
              )
            )
          })}
        <NextButton
          {...props}
          label="Next"
          onClick={() => onSubmit(values)}
          disabled={!(values.goal_diet || []).length}
        />
      </Wrap>
    )
  }

  if (step === 'HealthGoalSelection') {
    const hasHealthGoals = !!(values.for_health || []).length
    const unspecifiedOther = (values.for_health || []).includes('other')
    const needsWeightGoal = (values.for_health || []).includes('manage_weight') && !(values.weight_goal || []).length
    
    let healthGoals =
      available_health_goals &&
      available_health_goals.filter(v => {
        if (!props.partner.hidden_goals.includes(v.value)) {
          return v
        }
      })
    let idealGoals = idealFields.for_health
    idealGoals.options = healthGoals
    const allowUserToSkipIdeal = props.partner.allow_user_to_skip_ideal
    return (
      <Wrap>
        <ListSelection {...getListSelectionProps(idealGoals, 0)} animateOut={animatingOut} single_only={false} />
        <NextButton
          {...props}
          label="Next"
          onClick={() =>
            goToStep(
              hasHealthGoals ? 'HealthGoalDietList' : 'DietList',
              idealFields.for_health.options.length * 50 + 600
            )
          }
          disabled={!hasHealthGoals || animatingOut || needsWeightGoal || unspecifiedOther}
          allowSkip={!hasHealthGoals && !animatingOut && allowUserToSkipIdeal}
        />
      </Wrap>
    )
  }

  if (step === 'HealthGoalDietList') {
    //console.log(props.partner.hidden_goals)
    //console.log(props.partner.hidden_ideals)
    if (!(values.goal_diet || []).length && goalDiets && goalDiets.best && goalDiets.best.length) {
      setValues({ ...values, goal_diet: goalDiets.best })
    }

    // custom values are stored like "other:I have this other goal", so remove the custom portion
    const healthGoals = (values.for_health || []).map(goal => goal.split(':')[0])
    const goalLabels = _.compact(
      healthGoals.map(goal => (idealFields.for_health.options.find(option => option.value === goal) || {}).label)
    )


    const goalDietRenderer = ({ value, label, current, description, isSelected }) => {
      const diet = matrix.rows[0][matrix.columns.indexOf(value)]
      const isBest = value === goalDiets.best[0]
      const screener = responses.diet_screener
      const warnings = [
        ['Meat', screener.meat === 'no' && !diet?.no_meat],
        ['Poultry', screener.poultry === 'no' && !diet?.no_poultry],
        ['Fish', screener.fish === 'no' && !diet?.no_fish],
        ['Grains', screener.grains === 'no' && !diet?.no_grains],
        ['Dairy', screener.dairy === 'no' && !diet?.no_dairy]
      ]
        .filter(([label, active]) => active)
        .map(warning => warning[0])

      return (
        <>
          {diet ? (
            <DietWrap className={isBest ? 'featured' : ''}>
              <div className="label">{t(label)}</div>
              <DietImage style={{ backgroundImage: `url("${diet.fingerprint_photo_url}")` }} />
              <DietDescription>{t(description)}</DietDescription>
              {!!warnings.length && (
                <DietWarning>
                  <span>
                    <b>{t('Note:')}</b> {t('Can Contain')}
                  </span>
                  {warnings.map(warning => (
                    <WarningTag>{warning}</WarningTag>
                  ))}
                </DietWarning>
              )}
            </DietWrap>
          ) : (
            <></>
          )}
        </>
      )
    }
    //console.log(JSON.stringify(goalDiets.fields))
    //console.log(JSON.stringify(filteredGoalDiets))
    //console.log(`hidden_ideals`, props.partner.hidden_ideals)
    //console.log(`goalDiets.field`, goalDiets.fields)
    const idDietType = getDietType(matrix, idDiet)
    let filteredGoalDiets = [] //goalDiets.fields
    goalDiets?.fields?.forEach((field, index) => {
      //if (field !== 'current') {
      let new_field = field
      new_field.options = new_field.options.filter(
        item =>
          (item && item.value && !props.partner.hidden_ideals.includes(item.value) && item.value !== 'KET' && item.value !== '')
      )

      if (new_field.label == t("Other good diets for your goals")){
       // alert('new_field.options -'+JSON.stringify(new_field.options))
       // new_field.options.push(idDietType)
      }
      //console.log('filtered new_field', new_field)
      filteredGoalDiets.push(new_field)

      // }
    })
    
    // console.log('partner: ', props.partner)
    // console.log('partner.is_tangelo: ', props.partner.is_tangelo)
    //console.log(`filteredGoalDiets`, filteredGoalDiets)
    return (
      <Wrap>
        {goalDiets.idIsIdeal && (
          <CongratulationsCard message={t("Your current diet is optimal for reaching your health goals! Keep doing what you're doing and adjust intake based on any future weight goals.")} />
        )}
        {!goalDiets.idIsIdeal && (
          <>
            {!props.partner.is_tangelo && <GoalsList>
              <div className="goals-hd">{t("Goals")}</div>
              {goalLabels.map(label => (
                <div className="goal">{t(label)}</div>
              ))}
            </GoalsList>}
            <DietListInstructions>{t('Choose one that looks like how you want to eat')}</DietListInstructions>
            {filteredGoalDiets.map(field => (
              <>
                {field && field.name && field.options.length > 0 ? (
                  <ListSelection
                    key={field.name + field.label}
                    noLabel={filteredGoalDiets[0].options.length === 0}
                    {...getListSelectionProps(field, goalDiets.idIsIdeal ? 500 : 0)}
                    contentRenderer={goalDietRenderer}
                    single_only={true}
                  />
                ) : (
                  <></>
                )}
              </>
            ))}
          </>
        )}
        <NextButton
          {...props}
          label={t("Next")}
          onClick={() => onSubmit(values)}
          disabled={!(values.goal_diet || []).length}
        />
      </Wrap>
    )
  }

  /*if(step === 'PhotoSelection'){
    const idDietId = responses.diet_id[responses.diet_id.length - 2]
    const idealDietType = values.goal_diet[0]

    const { options, progress, diets } = getIdealSelectionInfo(idDietId, idealDietType, matrix, photoSelections)

    if(progress !== photoSelectionProgress){
      setPhotoSelectionProgress(progress)
    }

    if(!options.length){
      // if there is only 1 possible diet, skip photo selection
      if(diets.length === 1){
        setPhotoSelections(diets)
      }

      onSubmit(values)
      goToStep('Complete')
      return
    }

    return (
      <Wrap>
        <PhotoSelection
          selections={photoSelections}
          options={options}
          onSelect={option => setPhotoSelections([...photoSelections, option])}
          partner={props.partner}
          forIdeal
        ></PhotoSelection>
      </Wrap>
    )
  }*/

  if (step === 'Complete') {
    return null
  }
}
