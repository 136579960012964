import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import copy from 'copy-to-clipboard'
import moment from 'moment'

import {
  H6,
  Intent,
  Dialog,
  Popover,
  FormGroup,
  InputGroup,
  Spinner,
  Tooltip,
  Toaster,
  Menu,
  MenuItem,
  Icon
} from '@blueprintjs/core'

import colors from '../../lib/colors'
import * as constants from '../../lib/constants'
import t from '../../lib/i18n'
import { getUrlParam } from '../../lib/util'
import { signOut } from '../../actions/auth'
import { shareDashboard, shareDashboardClose, startNewWorkflow } from '../../actions/workflow'
import { Card as _Card, Header, Button } from './Components'

import Actions from './components/Actions'
import AssessmentLog from './components/AssessmentLog'
import NutrientChanges from './components/NutrientChanges'
import Stats from './components/Stats'
import Trends from './components/Trends'

import NaturesBountyProducts from '../../custom/naturesbounty/NaturesBountyProducts'

const toaster = Toaster.create()

const Card = styled(_Card)`
  max-width: 750px;
`

const SpinnerWrap = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`

const StyledSpinner = styled(Spinner)``

const PageHeaderCard = styled(Card)`
  padding: 20px 0 0;
  margin-bottom: 0;
  box-shadow: none;
  border-radius: 0;
  border: none;
`

const PageHeaderInner = styled.div`
  flex: 1;
`

const Avatar = styled.div`
  width: 90px;
  height: 90px;
  margin-right: 25px;
  border-radius: 100%;
  background: #fff;
  background-size: cover;
  color: ${colors.hexToRgb('#196161', 0.2)};
`

const LastAssessmentDate = styled(H6)`
  position: relative;
  margin-bottom: 5px;
  padding-bottom: 10px;

  :before {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    content: '';
    height: 1px;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
  }
`

const HeaderButton = styled(Button)`
  min-width: 0;
  min-height: 0;
  padding: 8px 10px;

  .bp3-button-text {
    color: ${(props) => props.theme.primary};
  }
`

const ShareButton = styled(HeaderButton)`
  margin-left: -10px;
`

const PageHeaderActions = styled.div`
  flex: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

const PageHeaderButton = styled(Button)`
  margin: 0 0 10px;
  white-space: nowrap;
`

const ShareForm = styled(FormGroup)`
  padding: 25px;
  margin-bottom: 0;
`

const Disclaimer = styled.div`
  margin: 25px auto;
  max-width: 750px;
  font-size: 12px;
  color: ${colors.gray};
  text-align: center;
`

const WorkflowDialog = styled(Dialog)`
  width: 100%;
  max-width: 1000px;
  height: 800px;
  padding-bottom: 0;

  &.wide {
    max-width: 1400px;
  }

  @media (max-width: ${constants.MOBILE_MAX_WIDTH}px) {
    margin: 0 auto;
    height: 100vh;
    border-radius: 0;

    & > .bp3-dialog-header {
      border-radius: 0;
    }
  }
`

const WorkflowFrame = styled.iframe`
  width: 100%;
  max-width: 1000px;
  height: 800px;
  border: none;
  border-radius: 0 0 6px 6px;

  &.wide {
    max-width: 1400px;
  }

  @media (max-width: ${constants.MOBILE_MAX_WIDTH}px) {
    margin: 0 auto;
    height: calc(100vh - 40px);
    border-radius: 0;
  }
`

const Wrap = styled.div`
  padding-bottom: 25px;

  .header {
    font-size: 1.4em;
  }

  .mobile {
    display: none;
  }

  @media (max-width: ${constants.MOBILE_MAX_WIDTH}px) {
    .mobile {
      display: initial;
    }

    .non-mobile {
      display: none;
    }

    ${PageHeaderCard} > .body {
      flex-direction: column;
      align-items: center;
    }

    ${PageHeaderInner} {
      margin-bottom: 25px;
      text-align: center;
    }

    ${Avatar} {
      margin: 0 0 25px;
    }

    ${LastAssessmentDate}:before {
      background: linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
    }

    ${PageHeaderButton} {
      min-width: 180px;
    }
  }
`

const getWorkflowData = () => {
  const admin = !!getUrlParam('detailed') && !!getUrlParam('aggregate')

  const share = useSelector((state) => state.shareDashboard)
  const { user: userData, partner: partnerData, diet: dietData } = useSelector((state) => state.data)
  const currentUserState = useSelector((state) => state.workflow.currentState)
  const currentUserId = useSelector((state) => state.general.userId)
  const currentPartnerId = useSelector((state) => state.general.partnerId)
  const currentUserSharingToken = useSelector((state) => state.workflow.sharingToken)

  const state = admin ? share.workflowShare || {} : currentUserState
  const userId = admin ? share.userId : currentUserId
  const partnerId = admin ? share.partnerId : currentPartnerId
  const sharingToken = admin ? share.shareToken : currentUserSharingToken
  const idDietId = admin ? state.diet_id?.id : state.diet_id_id
  const idealDietId = admin ? state.diet_ideal?.id : state.diet_ideal_id
  const initializing = admin ? share.initializing : !state.initialized

  const partner = partnerId ? partnerData[partnerId] : null
  const user = userId ? userData[userId] : null
  const idDiet = idDietId ? dietData[idDietId] : null
  const idealDiet = idealDietId ? dietData[idealDietId] : null

  return {
    admin,
    state,
    initializing,
    firstName: user && user.first_name ? user.first_name : null,
    partner,
    idDiet,
    idealDiet,
    sharingToken
  }
}

const TrackingDashboard = () => {
  const dispatch = useDispatch()

  const nohelp = useSelector((state) => state.general.viewCustomizations.nohelp)
  const sharingDisplayOpen = useSelector((state) => state.workflow.sharingDisplayOpen)
  const [activeWorkflow, setActiveWorkflow] = useState({})

  const { admin, state, firstName, partner, idDiet, idealDiet, sharingToken, initializing } = getWorkflowData()

  if (initializing) {
    return (
      <SpinnerWrap>
        <StyledSpinner />
      </SpinnerWrap>
    )
  }

  const partnerNavigationActive = partner.navigation_active
  const userNavigationActive = state.navigation_active
  const idIsIdeal = idDiet && idealDiet && idDiet.id === idealDiet.id
  const sharingUrl = sharingToken
    ? `${window.location.protocol}//${window.location.host}${admin ? '?detailed=1' : ''}#/s/${sharingToken}`
    : ''
  const nbty = partner.code === 'nbty'
  const meredith = partner.code.includes('meredith')

  const startWorkflow = (type) => dispatch(startNewWorkflow(type))

  const onHelpButtonClick = () => {
    window.open('https://dietid.com/support', '_blank')
    // Analytics.trackPageView('/support')
  }

  const closeSharingPanel = () => dispatch(shareDashboardClose())

  const copySharingUrlToClipboard = () => {
    copy(sharingUrl)
    closeSharingPanel()
    toaster.show({ message: 'Copied share link to clipboard', intent: Intent.SUCCESS, icon: 'tick' })
  }

  return (
    <Wrap>
      <PageHeaderCard horizontal>
        <Avatar>
          <Icon icon="user" iconSize={90} />
        </Avatar>
        <PageHeaderInner>
          <Header>
            {firstName && (
              <>
                <span className="non-mobile">{t("{name}'s Assessment Dashboard", { name: firstName })}</span>
                <span className="mobile">{t("{name}'s Dashboard", { name: firstName })}</span>
              </>
            )}
          </Header>
          <LastAssessmentDate>
            {t('Last Assessment:')} {moment(state.created_at).format('MMMM Do, YYYY')}
          </LastAssessmentDate>
          {!admin && (
            <>
              <Popover isOpen={sharingDisplayOpen} onClose={closeSharingPanel}>
                <ShareButton
                  text="Share"
                  onClick={() => dispatch(shareDashboard())}
                  active={sharingDisplayOpen}
                  minimal
                />
                <ShareForm label={t('You can share these results with this link')} labelFor="share-input">
                  <InputGroup
                    id="share-input"
                    value={sharingUrl}
                    onFocus={(e) => e.target.select()}
                    rightElement={
                      sharingToken ? (
                        <Tooltip content={t('Copy to clipboard')}>
                          <Button icon="clipboard" onClick={copySharingUrlToClipboard} minimal />
                        </Tooltip>
                      ) : (
                        <Spinner size={16} minimal />
                      )
                    }
                    autoFocus
                  />
                </ShareForm>
              </Popover>
              {!nohelp && <HeaderButton text="Help" onClick={onHelpButtonClick} minimal />}
              {!meredith && (
                <Popover>
                  <HeaderButton text="Account" minimal />
                  <Menu>
                    <MenuItem text={t('Sign Out')} onClick={() => dispatch(signOut())} />
                  </Menu>
                </Popover>
              )}
            </>
          )}
        </PageHeaderInner>
        {!admin && (
          <PageHeaderActions>
            <PageHeaderButton
              text={nbty ? 'Retake the quiz' : partner.disable_ideal ? 'Update ID' : partner.code === 'madeeasymeals' ? 'Update Score & Diet' : 'Update ID & Goal'}
              onClick={() => startWorkflow()}
            />
            {idDiet && idealDiet && (
              <>
                {!partnerNavigationActive && (
                  <PageHeaderButton
                    text={partner.code === 'madeeasymeals' ? 'Get New Healthy Eating Score' : 'Update your ID'}
                    onClick={() => startWorkflow('id')}
                  />
                )}
                {!partnerNavigationActive && !partner.disable_ideal && (
                  <PageHeaderButton
                    text={partner.code === 'madeeasymeals' ? 'Update Made Easy Diet' : 'Update Goal'}
                    onClick={() => startWorkflow('ideal')}
                  />
                )}
                {partnerNavigationActive && !userNavigationActive && (
                  <PageHeaderButton
                    text="Start challenges"
                    onClick={() => (window.location.href = '/navigation/start')}
                  />
                )}
              </>
            )}
          </PageHeaderActions>
        )}
      </PageHeaderCard>
      <Stats partner={partner} tracking={state.tracking} />
      {!idIsIdeal && state.diet_ideal && state.to_increase && state.to_decrease && (
        <Actions
          header={admin && firstName ? t("{name}'s Latest Actions", { name: firstName }) : 'Your Actions'}
          idealDiet={idealDiet}
          goals={state.goals}
          toIncrease={state.to_increase}
          toDecrease={state.to_decrease}
          dietRestrictions={state.diet_restrictions}
        />
      )}
      <Trends partner={partner} tracking={state.tracking} onWorkflowClick={(workflow) => setActiveWorkflow(workflow)} />
      <NutrientChanges partner={partner} tracking={state.tracking} goals={state.goals} />
      <AssessmentLog
        header={admin && firstName ? t("{name}'s Assessment Log", { name: firstName }) : 'Your Assessment Log'}
        partner={partner}
        tracking={state.tracking}
        onWorkflowClick={(workflow) => setActiveWorkflow(workflow)}
      />
      {nbty && (
        <NaturesBountyProducts
          idDietCode={idDiet.code}
          idNutrition={state.diet_id_nutrition_info}
          userInfoCustomField={state.custom_data}
        />
      )}
      <Disclaimer>
        {nbty && (
          <>
            {t(
              'Health is personal. If you are pregnant, nursing, taking any medications, have been diagnosed with any medical condition, or are planning any medical or surgical procedure, please consult your doctor before taking any new supplements.'
            )}
            <br />
          </>
        )}
        {!nbty && state.disclaimer && (
          <>
            {t(state.disclaimer)}
            <br />
          </>
        )}
        <a
          href={
            partner && partner.code === 'nbty'
              ? 'https://dietid.com/terms-of-service-diet-id-naturesbounty'
              : t('https://dietid.com/terms-of-service')
          }
          target="_blank"
        >
          {t('Terms of Service')}
        </a>
      </Disclaimer>
      <WorkflowDialog
        isOpen={!!activeWorkflow.url}
        title={`Assessment on ${moment(activeWorkflow.created_at).format('MMMM Do, YYYY')}`}
        onClose={() => setActiveWorkflow({})}
        className={admin ? 'wide' : undefined}
      >
        <WorkflowFrame
          src={
            admin
              ? activeWorkflow?.url?.replace('#/s/', `?view=nochat${admin ? '&detailed=1' : ''}#/s/`)
              : activeWorkflow?.url
          }
          className={admin ? 'wide' : undefined}
        />
        {/* ?.replace('#/s/', `?view=nochat${admin ? '&detailed=1' : ''}#/s/`) */}
      </WorkflowDialog>
    </Wrap>
  )
}

export default TrackingDashboard
