import _ from 'lodash'

const partnerData = {
  workflowUserInfo: {}
}

const receiveMessage = (action, payload) => {
  if (action) {
    console.log('Received integration message', JSON.stringify({ action, payload }))
  }

  if (action === 'setworkflowuserinfo') {
    if (validateWorkflowUserInfo(payload)) {
      partnerData.workflowUserInfo = payload
    }

    return
  }

  if (action) {
    throw new Error(`Unknown integration action: ${action}`)
  }
}

export const sendMessage = (eventName, payload) => {
  const message = { source: 'DietID', eventName, payload }
  console.log('Sent integration message', JSON.stringify(message))
  // Ensure window and window.parent are available to avoid errors in environments where they might not be defined
  if (typeof window !== 'undefined') {
    if (window.parent) {
      window.parent.postMessage(JSON.stringify(message), '*') // Use '*' for targetOrigin in web environments
    }
    // Check if ReactNativeWebView is available for communication in a React Native webview
    if (window.ReactNativeWebView && typeof window.ReactNativeWebView.postMessage === 'function') {
      window.ReactNativeWebView.postMessage(JSON.stringify(message)) // No target origin needed here
    }
  } else {
    console.error('Window object not available.')
  }
  if(window?.LogRocket && window?.LogRocket?._isInitialized){
    window.LogRocket.track(eventName, payload)
    console.log('Sent LogRocket event')
  }
}

export const init = () => {
  window.addEventListener('message', ({ data }) => {
    let info = {}

    try {
      info = JSON.parse(data)
    } catch (e) {}

    receiveMessage(info.action, info.payload)
  })

  sendMessage('ready')
}

export const sendWorkflowProgressChange = ({
  section,
  percentComplete,
  completedSteps,
  totalSteps,
  workflowID,
  rawData
}) => sendMessage('workflowprogresschange', { section, percentComplete, completedSteps, totalSteps, workflowID }) //, rawData

export const sendDisplay = component => sendMessage('display', { component })

export const sendWorkflowComplete = webhookUrlHash => sendMessage('workflowcomplete', { webhookUrlHash })

const workflowUserInfoFieldMap = {
  gender: 'gender',
  ageInYears: 'age_years',
  weightTrend: 'weight_trend',
  activityLevel: 'activity_level',
  customField: 'custom_field',
  heightInCentimeters: 'height_cm',
  weightInKilograms: 'weight_kg',
  heightInInches: 'height_inches',
  weightInPounds: 'weight_lbs'
}

const workflowUserInfoValueMap = {
  gender: { Male: 'male', Female: 'female' },
  weightTrend: { Rising: 'rising', Constant: 'constant', Falling: 'falling' },
  activityLevel: {
    Sedentary: 'minimal',
    BelowGuidelines: 'light',
    MeetsGuidelines: 'moderate',
    AboveGuidelines: 'active',
    HighlyActive: 'intense'
  }
}

const validateWorkflowUserInfo = data => {
  const errors = []

  if ('gender' in data && !Object.keys(workflowUserInfoValueMap.gender).includes(data.gender)) {
    errors.push(`Invalid gender value: ${data.gender}`)
  }

  if ('ageInYears' in data && (!_.isNumber(data.ageInYears) || data.ageInYears < 18 || data.ageInYears > 120)) {
    errors.push(`Invalid ageInYears value: ${data.ageInYears}`)
  }

  if ('weightTrend' in data && !Object.keys(workflowUserInfoValueMap.weightTrend).includes(data.weightTrend)) {
    errors.push(`Invalid weightTrend value: ${data.weightTrend}`)
  }

  if ('activityLevel' in data && !Object.keys(workflowUserInfoValueMap.activityLevel).includes(data.activityLevel)) {
    errors.push(`Invalid activityLevel value: ${data.activityLevel}`)
  }

  if (
    'heightInCentimeters' in data &&
    (!_.isNumber(data.heightInCentimeters) || data.heightInCentimeters < 100 || data.heightInCentimeters > 250)
  ) {
    errors.push(`Invalid heightInCentimeters value: ${data.heightInCentimeters}`)
  }

  if (
    'weightInKilograms' in data &&
    (!_.isNumber(data.weightInKilograms) || data.weightInKilograms < 20 || data.weightInKilograms > 150)
  ) {
    errors.push(`Invalid weightInKilograms value: ${data.weightInKilograms}`)
  }

  if (
    'heightInInches' in data &&
    (!_.isNumber(data.heightInInches) || data.heightInInches < 3 * 12 || data.heightInInches > 9 * 12)
  ) {
    errors.push(`Invalid heightInInches value: ${data.heightInInches}`)
  }

  if (
    'weightInPounds' in data &&
    (!_.isNumber(data.weightInPounds) || data.weightInPounds < 60 || data.weightInPounds > 700)
  ) {
    errors.push(`Invalid weightInPounds value: ${data.weightInPounds}`)
  }

  if (!errors.length) {
    return true
  }

  throw new Error(`Invalid workflow info. ${errors.join(', ')}`)
}

export const getWorkflowUserInfo = () =>
  Object.entries(workflowUserInfoFieldMap).reduce(
    (map, [field, fieldId]) =>
      field in partnerData.workflowUserInfo
        ? {
            ...map,
            [fieldId]: workflowUserInfoValueMap[field]
              ? workflowUserInfoValueMap[field][partnerData.workflowUserInfo[field]]
              : partnerData.workflowUserInfo[field]
          }
        : map,
    {}
  )
