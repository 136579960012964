import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import colors from 'app/lib/colors'
import Icon from "./Icon";

const SliderWrap = styled.div`
  position: relative;
  width: 140px;
  height: 6px;
  background: #f5f5f5;

  .good-bar {
    position: absolute;
    top: 0;
    bottom: 0;
    background: ${colors.good};
  }

  &.has-min,
  &.has-max {
    background: ${colors.bad};
  }

  &.has-min .good-bar {
    border-left: 2px solid #fff;
  }

  &.has-max .good-bar {
    border-right: 2px solid #fff;
  }

  .dot {
    position: absolute;
    top: calc(50% - 9px);
    left: 50%;
    width: 18px;
    height: 18px;
    margin-left: -9px;
    border-radius: 100%;
    text-align: center;
    background: #ddd;

    &.good {
      background: #fff;
      color: ${colors.good};
       & svg {
      background: #fff;
      color: ${colors.good};
      }
    }

    &.bad {
      background: ${colors.bad};
       & svg {
      color: #fff;
      font-size: 10px;
      margin-bottom: 1px;
      }
    }

    .icon {
      position: relative;

      &:before {
        position: relative;
        display: block;
        width: 18px;
        line-height: 18px;
        font-size: 18px;
        transform: scale(1.3);
      }
    }
  }
`

const NutrientSlider = ({ nutrientInfo, nutrient, value, dailyCalories }) => {
  const dv = (nutrientInfo[nutrient] || {}).dv

  if (typeof dv === 'undefined' || dv === null) {
    return null
  }

  let [minDv, maxDv] = _.isArray(dv) ? dv : [dv, dv]

  const hasMin = minDv !== null
  const hasMax = maxDv !== null

  const haveAdjustedDvs = [
    'added-sugars-by-total-sugars',
    'total-saturated-fatty-acids-sfa',
    'sodium',
    'total-dietary-fiber',
    'cholesterol'
  ]

  // adjust DV limits by calorie proportions
  if (dailyCalories !== null && haveAdjustedDvs.includes(nutrient)) {
    if (hasMin) {
      minDv = minDv * (dailyCalories / 2000)
    }

    if (hasMax) {
      maxDv = maxDv * (dailyCalories / 2000)
    }
  }

  const goodLeft = hasMin ? (hasMax ? 0.25 : 0.5) : 0
  const goodRight = hasMax ? (hasMin ? 0.25 : 0.5) : 0

  const range = hasMin && hasMax ? (maxDv - minDv) * 2 : (hasMin ? minDv : maxDv) * 2
  const minValue = hasMin && hasMax ? minDv - range / 4 : (hasMin ? minDv : maxDv) - range / 2
  const dotLeft = Math.max(0, Math.min((value - minValue) / range, 1))

  const isGood = (!hasMin || value >= minDv) && (!hasMax || value <= maxDv)

  return (
    <SliderWrap className={_.compact([hasMin ? 'has-min' : null, hasMax ? 'has-max' : null]).join(' ')}>
      <div
        className="good-bar"
        style={{
          left: `${goodLeft * 100}%`,
          right: `${goodRight * 100}%`
        }}
      ></div>
      <div className={`dot ${isGood ? 'good' : 'bad'}`} style={{ left: `${dotLeft * 100}%` }}>
        <Icon className={`icon`} name={isGood ? 'checkmark-circle' : 'alert'} />
      </div>
    </SliderWrap>
  )
}

export default NutrientSlider
