import React, { Component } from 'react'
import styled from 'styled-components'
import loadGoogleMapsApi from 'load-google-maps-api'
import NextButton from './NextButton'
import t from '../../../lib/i18n'

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export default class QuestionAddress extends Component {
  constructor(props) {
    super(props)
    this.autocompleteInputRef = React.createRef()
    this.state = {
      value: null,
      unitNumber: '',
      deliveryInstructions: '',
      googleMapsLoaded: false,
      isValidAddressSelected: false,
      questionFailedLoading: false,
      clickedNextWithoutValidAddress: false,
    }
  }

  componentDidMount() {
    this.loadGoogleMaps()
  }

  componentWillUnmount() {
    if (this.state.googleMapsLoaded) {
      window.google.maps.event.clearInstanceListeners(this.autocomplete)
    }
  }
  loadGoogleMaps = () => {
    loadGoogleMapsApi({
      key: 'AIzaSyB2SNyrJfTGO1ay8_sK4EkeO34REcVMPdk', // Replace with actual key
      libraries: ['places']
    })
      .then(googleMaps => {
        this.setState({ googleMapsLoaded: true, questionFailedLoading: false })
        this.initAutocomplete(googleMaps)
      })
      .catch(error => {
        console.error(error)
        this.setState({ questionFailedLoading: true })
      })
  }

  initAutocomplete = googleMaps => {
    this.autocomplete = new googleMaps.places.Autocomplete(this.autocompleteInputRef.current, {
      types: ['address'],
      componentRestrictions: { country: 'us' }
    })
    this.autocomplete.addListener('place_changed', this.handlePlaceSelect)
  }
  handlePlaceSelect = () => {
    const place = this.autocomplete.getPlace()
    if (place.geometry) {
      this.setState({ value: place.formatted_address, isValidAddressSelected: true, clickedNextWithoutValidAddress: false })
    }
  }
  reloadQuestion = () => {
    this.setState({ googleMapsLoaded: false, questionFailedLoading: false }, () => {
      this.loadGoogleMaps()
    })
  }
  handleNext = (is_optional = false, keyValue = '') => {
    if (is_optional === true && keyValue !== '') {
      this.props.onSubmit({ key: keyValue, label: 'SKIPPED' })
    } else {
      if (this.state.value && this.state.isValidAddressSelected) {
        //console.log('full address object ', this.state.value)
        // Check if step prop is provided and has at least one element with question_id
        const questionId =
          this.props.step &&
          this.props.step.fields &&
          this.props.step.fields[0] &&
          this.props.step.fields[0].question_id
        if (questionId) {
          const submission = {
            key: questionId,
            label: {
              address: this.state.value.toString(),
              unitNumber: this.state.unitNumber.toString(),
              deliveryInstructions: this.state.deliveryInstructions.toString()
            }
          }
          this.props.onSubmit(submission)
          this.setState({ value: null, isValidAddressSelected: false })
        } else {
          //console.log(this.props.step)
          //console.error('Step prop is not provided or the first element does not contain a question_id property');
        }
      }
    }
  }

  handleUnselectAddress = () => {
    // console.log('handleUnselectAddress')
    this.setState({ clickedWithoutSelectingAddress: true })
  }
  render() {
    const { step } = this.props
    return (
      <Wrap>
        {this.state.googleMapsLoaded ? (
          <>
          {(this.state.clickedNextWithoutValidAddress) && <div style={{color: "red"}}>Please select an address from the dropdown list.</div>}
            <input
              style={{
                margin: 60,
                width: '100%',
                maxWidth: 400
              }}
              ref={this.autocompleteInputRef}
              type="text"
              placeholder={t('Enter your address')}
              value={this.state.value || ''}
              onChange={e => {
                this.setState({
                  value: e.target.value,
                  // Set isValidAddressSelected to false if the input is cleared or modified
                  isValidAddressSelected: e.target.value && e.target.value === this.state.value
                })
              }}
            />
            {this.state.value && (
              <>
                <input
                  style={{ margin: 20, marginTop: 0, width: '100%', maxWidth: 400 }}
                  type="text"
                  placeholder={t('Unit Number')}
                  value={this.state.unitNumber}
                  onChange={e => this.setState({ unitNumber: e.target.value })}
                />
                <input
                  style={{ margin: 20, width: '100%', maxWidth: 400 }}
                  type="text"
                  placeholder={t('Delivery Instructions')}
                  value={this.state.deliveryInstructions}
                  onChange={e => this.setState({ deliveryInstructions: e.target.value })}
                />
              </>
            )}
          </>
        ) : (
          <p>Loading Question...</p>
        )}
        <div style={{ position: 'relative', display: 'inline-block' }}>
          <NextButton
            {...this.props}
            onClick={this.handleNext}
            disabled={!this.state.isValidAddressSelected}
            label="Next"
            zIndex={1}
          />
          {!this.state.isValidAddressSelected && (
            <button
              onClick={() => {
                this.setState({ clickedNextWithoutValidAddress: true });
              }}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: '30%',
                backgroundColor: 'transparent',
                border: 'none',
                cursor: 'pointer',
                zIndex: !this.state.isValidAddressSelected ? 5 : 3,
              }}
            />
          )}
        </div>
        {this.props.questionFailedLoading && (
          <NextButton {...this.props} onClick={this.reloadQuestion} label="Reload Question" />
        )}
      </Wrap>
    )
  }
}
